import { SiteContext } from '../context.js';
import { setCookie } from '../../common/util.js';

export async function checkActiveAuthToken(sc: SiteContext, authHost: string) {
	
	const res = await fetch(`https://${authHost}/auth/active`, {
		credentials: 'include',
		mode: 'cors',
	});
	if (!res.ok) return;
	
	const json: {
		cookie?: string;
		remember?: boolean;
	} = await res.json();
	
	if (json.cookie && json.cookie != sc.context.peek().cookies.auth) {
		setCookie('auth', json.cookie, json.remember ? 86400 * 90 : 0);
		location.reload();
	}
	
}
